exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-posts-jsx": () => import("./../../../src/pages/posts.jsx" /* webpackChunkName: "component---src-pages-posts-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-astro-to-ghost-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/src/posts/astro-to-ghost.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-astro-to-ghost-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-c-windows-vscode-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/src/posts/c-windows-vscode/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-c-windows-vscode-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-img-2-pdf-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/src/posts/img2pdf.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-img-2-pdf-md" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-logo-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/src/posts/logo.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-logo-md" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-manim-vscode-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/src/posts/manim-vscode.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-manim-vscode-md" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-page-size-print-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/src/posts/page-size-print.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-page-size-print-md" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-palindrome-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/src/posts/palindrome.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-palindrome-md" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-projects-manim-binder-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/src/posts/projects/manim-binder.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-projects-manim-binder-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-weasyprint-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/src/posts/weasyprint.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-weasyprint-md" */),
  "component---src-templates-blog-post-js-content-file-path-src-posts-xor-unique-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/buildhome/repo/src/posts/xor-unique/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-posts-xor-unique-index-mdx" */),
  "component---src-templates-tags-list-tsx": () => import("./../../../src/templates/tags-list.tsx" /* webpackChunkName: "component---src-templates-tags-list-tsx" */)
}

